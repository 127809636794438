<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outerOne">
      <div>
        <img src="@/assets/img/dsBJ4.png" alt="" />
        <div>
          <div class="oneTitle">
            {{ $t("processIntegrationDepartment.PID_oneTitle") }}
          </div>
          <div class="oneCont">
            {{ $t("processIntegrationDepartment.PID_oneCont") }}
          </div>
        </div>
      </div>
    </div>

    <div class="outerTwo">
      <div class="fourTip fourTipOne">
        {{ $t("processIntegrationDepartment.PID_fourTipOne") }}
      </div>
      <div class="fourCont fourContOne">
        <div
          class="contItem"
          v-for="(item, index) in $t(
            'processIntegrationDepartment.PID_fourContOne'
          )"
          :key="index"
        >
          {{ item }}
        </div>
      </div>

      <div class="fourTip fourTipTwo">
        {{ $t("processIntegrationDepartment.PID_fourTipTwo") }}
      </div>
      <div class="fourContTwo">
        <div class="projectList" v-if="project.length > 0">
          <div
            v-for="(item, index) in project"
            :key="index"
            @mouseenter="enters(index)"
            @mouseleave="leaver(index)"
            @click="projectDetail(item)"
          >
            <img :src="item.image" alt="" v-if="index < 3" />
            <div class="itemHoverCont" v-if="item.isHover == true">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：工艺集成部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-10-08 14:59
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "departmentSettings",
        },
        {
          title_zh: "工艺集成部",
          title_en: "Process Integration Department",
          path: "",
        },
      ],

      project: [],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.handleScrollboxNow();
    this.getProject();
  },
  methods: {
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-9-29
     */
    handleScrollboxNow() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".outerOne").offset().top - 950) {
        this.$(".outerOne>div").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipOne").offset().top - 600) {
        this.$(".fourTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContOne").offset().top - 580) {
        this.$(".fourContOne").addClass("showDiv");
      }
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$(".fourTipTwo").offset().top - 950) {
        this.$(".fourTipTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContTwo").offset().top - 1050) {
        this.$(".fourContTwo").addClass("showDiv");
      }
    },

    /**
     * 研发项目
     * 刘嘉鑫
     * 2022-10-8
     */
    getProject() {
      this.$request({
        url: "/Index/index",
        data: {},
      }).then((res) => {
        console.log("首页", res);
        for (let a in res.project_list) {
          res.project_list[a].isHover = false;
        }
        this.project = res.project_list;
      });
    },

    /**
     * 研发项目动画效果
     * 刘嘉鑫
     * 2022-10-8
     */
    enters(index) {
      this.project[index].isHover = true; // 当前鼠标悬浮所在的图片隐藏
    },
    leaver(index) {
      this.project[index].isHover = false; // 文字隐藏
    },

    /**
     * 研发项目详情
     * 刘嘉鑫
     * 2022-10-8
     */
    projectDetail(item) {
      this.$router.push({
        path: "/RDproject",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
}
.outerOne {
  padding: 80px 310px;
  > div {
    display: flex;
    align-items: flex-start;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 30px;
    }

    div {
      .oneTitle {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }
      .oneCont {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}

.outerTwo {
  .fourTipOne,
  .fourContOne,
  .fourTipTwo,
  .fourContTwo {
    transition: all 0.6s ease-in 0s;
    transform: translateY(30px);
    opacity: 0;
  }

  .fourTip {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    padding: 0px 310px 60px;
  }

  .fourContOne {
    margin-bottom: 80px;
  }

  .fourCont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .contItem {
      width: 33.33%;
      height: 220px;
      text-align: center;
      line-height: 220px;
      margin: auto 0;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      background: #1a2a60;
    }

    .contItem:nth-child(2n) {
      color: #1a2a60 !important;
      background: #ffffff !important;
    }
  }

  .fourContTwo {
    .projectList {
      background: #1a2a60;
      padding: 120px 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        overflow: hidden;
        position: relative;
        cursor: pointer;
      }

      .itemHoverCont {
        background: rgba(12, 36, 85, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.2s ease-out 0s;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 520px;
        height: 348px;
      }
    }
  }
}
</style>